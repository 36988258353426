/**
 * @generated SignedSource<<7923c51761b0840642f6a26b743061af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemHeartWrapper_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Heart_viewer">;
  readonly " $fragmentType": "ItemHeartWrapper_viewer";
};
export type ItemHeartWrapper_viewer$key = {
  readonly " $data"?: ItemHeartWrapper_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemHeartWrapper_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "loadPortfolioData"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "userIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemHeartWrapper_viewer",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "loadPortfolioData",
          "variableName": "loadPortfolioData"
        },
        {
          "kind": "Variable",
          "name": "userIds",
          "variableName": "userIds"
        }
      ],
      "kind": "FragmentSpread",
      "name": "Heart_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "c784946f24262febb3689ea5f0766560";

export default node;
