/**
 * @generated SignedSource<<db5381254526bec467be81383f42dd1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemFolderWrapper_item$data = {
  readonly folderPortfolioItems?: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"Folder_folderPortfolioItems">;
  } | null> | null;
  readonly serviceId: string | null;
  readonly " $fragmentType": "ItemFolderWrapper_item";
};
export type ItemFolderWrapper_item$key = {
  readonly " $data"?: ItemFolderWrapper_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemFolderWrapper_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "loadPortfolioData"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemFolderWrapper_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "condition": "loadPortfolioData",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "folderPortfolioItems",
          "args": [
            {
              "kind": "Literal",
              "name": "portfolioType",
              "value": "FOLDER"
            },
            {
              "kind": "Variable",
              "name": "userId",
              "variableName": "userId"
            }
          ],
          "concreteType": "PortfolioItemV2",
          "kind": "LinkedField",
          "name": "portfolioItems",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Folder_folderPortfolioItems"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "9c1383daaa45d1ceab83b5c0d9d2ce47";

export default node;
